import React, { useCallback } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
  GoogleReCaptchaCheckbox,
  GoogleReCaptchaProvider
} from '@google-recaptcha/react';

import {
  CheckBox,
  ErrorSpan,
  FormCaption,
  FormSubmit,
  InformSpan,
  LinkText
} from '../../common/components';
import { Input } from '../../common/components/form/input.component';
import {
  AUTH_PATTERN,
  AUTH_VALIDATION_CONFIG,
  EmailErrors,
  PasswordErrors,
  TermsErrors
} from './validation.pattern';
import { APP_KEYS } from '../../common/consts';
import { ROUTER_KEYS, STORAGE_KEYS } from '../../common/consts/app-keys.const';
import { ISignin } from '../types';
import { authService } from '../service/auth.service';

type Inputs = {
  email: string;
  password: string;
  terms: any;
};

export const Signup = () => {
  const history = useHistory();
  const [isTermsAccepted, setIsTermsAccepted] = React.useState<boolean | null>(null);
  const [isRecaptchaError, setIsRecaptchaError] = React.useState(false);
  const [isRecaptchaTouch, setIsRecaptchaTouch] = React.useState<boolean | null>(
    null
  );
  const recaptcaErrorHandler = useCallback(() => {
    setIsRecaptchaError(true);
    setIsRecaptchaTouch(true);
  }, []);

  const recaptchaChangeHandler = useCallback(() => {
    setIsRecaptchaError(false);
    setIsRecaptchaTouch(true);
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<Inputs>();

  React.useEffect(() => {
    if (watch('terms') === true) {
      setIsTermsAccepted(true);
    }
  }, [watch('terms')]);

  const signup = useMutation(
    [APP_KEYS.QUERY_KEYS.USER],
    async (data: ISignin) => authService.signup(data),

    {
      onSuccess: async () => {
        localStorage.setItem(STORAGE_KEYS.EMAIL, watch('email'));
        history.push(APP_KEYS.ROUTER_KEYS.ACTIVATE_ACCOUNT);
      },
      onError: (err: any) => {
        if (
          err.response.data.message === 'Credentials taken' ||
          err.response.data.message === 'User already exists'
        ) {
          errors.email = {
            message: 'Account with this email address already exists',
            type: 'manual'
          };
        }
      }
    }
  );

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (isRecaptchaTouch === null) {
      setIsRecaptchaTouch(false);
      return;
    }

    if (data.terms !== true) {
      setIsTermsAccepted(false);
      return;
    }
    signup.mutate(data);
  };

  return (
    <>
      <FormCaption>Create an account</FormCaption>
      <InformSpan> Please enter your contact details to register </InformSpan>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-7">
        <Input
          errors={errors}
          name="email"
          label="Email"
          placeholder="Enter your email"
          type="email"
          register={register.bind(null, 'email', {
            required: { message: EmailErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.email,
              message: EmailErrors.pattern
            }
          })}
        />
        <Input
          errors={errors}
          name="password"
          label="Password"
          placeholder="Enter your password"
          type="password"
          register={register.bind(null, 'password', {
            required: { message: PasswordErrors.required, value: true },
            pattern: {
              value: AUTH_PATTERN.password,
              message: PasswordErrors.pattern
            },
            minLength: {
              value: AUTH_VALIDATION_CONFIG.password.minLength,
              message: PasswordErrors.minLength
            },
            maxLength: {
              value: AUTH_VALIDATION_CONFIG.password.maxLength,
              message: PasswordErrors.maxLength
            }
          })}
        />
        <div className="relative my-1 w-fit google-recaptcha-container">
          <GoogleReCaptchaProvider
            type="v2-invisible"
            siteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
          >
            <GoogleReCaptchaCheckbox
              onChange={recaptchaChangeHandler}
              onError={recaptcaErrorHandler}
              expiredCallback={recaptcaErrorHandler}
            />
          </GoogleReCaptchaProvider>

          {(isRecaptchaError || isRecaptchaTouch === false) && (
            <ErrorSpan>You must confirm that you are not a robot</ErrorSpan>
          )}
        </div>
        <CheckBox
          description="I agree to the terms and conditions and the privacy policy"
          register={register.bind(null, 'terms')}
          terms
        />
        {isTermsAccepted === false && <ErrorSpan>{TermsErrors.required}</ErrorSpan>}

        <FormSubmit value="Create Account" />

        <LinkText
          linkText="Sign in"
          to={ROUTER_KEYS.SIGNIN}
          text="Already have an account?"
        />
      </form>
    </>
  );
};
