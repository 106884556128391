import React from 'react';
import { SubscriptionPlan } from './subscription-plan.component';

interface IProps {
  refScroll: React.RefObject<HTMLDivElement>;
}

export const SubscriptionsPlans = ({ refScroll }: IProps) => (
  <div className="w-screen mt-9 mb-16">
    <div className="w-full flex justify-center align-middle flex-col gap-4 mb-10">
      <h1
        className="uppercase text-3xl font-semibold mx-2 sm:mx-auto  text-center"
        ref={refScroll}
      >
        Select your subscription plans
      </h1>
    </div>
    <div className="flex mt-10 w-4/5 mx-auto gap-8 justify-center flex-col laptop:flex-row max-w-fit">
      <SubscriptionPlan
        caption="Free trial"
        duration="month"
        plan="free"
        key={1}
        description="Try out a fully featured TouchPose for one month"
      />
      <SubscriptionPlan
        caption="Monthly Subscription"
        duration="month"
        plan="1m"
        key={2}
        description="Get the flexibility of a month-to-month subscription"
      />
      <SubscriptionPlan
        caption="Annual Subscription"
        duration="year"
        plan="1y"
        key={3}
        description="Get a discount with an annual subscription"
      />
    </div>
  </div>
);
